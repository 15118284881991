import { RawDraftContentState, convertFromRaw, convertToRaw } from 'draft-js';
import { FormStep, Mapper } from '../@Types/FormStep';
import FormStepTypes, {
    EntityValueDataTypes,
} from '../constants/FormStepTypes';
import { DependencyStore, StepDependency } from './Form';
import { CustomStep } from '../FormSteps/CustomStep';
import { CBRFormStep } from '../@Types/CBRFormStep';
import { MapperElement } from '../@Types/MapperElement';
import { ValuesStore } from '../States/SiteSlice';
import { calcStepDeps } from '../FormSteps/StepHooks';
import { calcRecursiveData } from '../Utils/FormStepFunctions';
import { isValidPhoneNumber } from '../Utils/PhoneFunctions';

/**
 * Function that cals the value of a step to output on submit
 */
export const calcValue = (
    idStep: string,
    steps: Record<string, FormStep>,
    values: Record<string, any>,
    customSteps: Record<string, CustomStep> | undefined,
    deleteIds: string[],
    value = values[idStep]
): any => {
    const step = steps[idStep];
    if (!step) return value;
    const custom = customSteps?.[step?.type];
    if (custom !== undefined) {
        return custom.calcValue?.(step, value) ?? value;
    } else {
        switch (step.type) {
            case FormStepTypes.TEXTINPUT: {
                if (
                    step.clientInfoType === 'cel' ||
                    step.validation?.type === 'PHONE'
                ) {
                    if (isValidPhoneNumber(value)) return value;
                    return '';
                } else {
                    return value;
                }
            }
            case FormStepTypes.TEXTAREA: {
                if (step.hasTextEditor) {
                    const currentContent = value.getCurrentContent();
                    return {
                        value: currentContent.getPlainText(),
                        draft: convertToRaw(currentContent),
                    };
                } else {
                    return {
                        value,
                        draft: stringToDraft(value),
                    };
                }
            }
            case FormStepTypes.FILEUPLOAD: {
                return value.map((val: any) => ({
                    fileName: val.fileName,
                    S3Key: val.S3Key,
                }));
            }
            case FormStepTypes.SELECTOR:
            case FormStepTypes.CLASSIFIER_SELECTOR: {
                return value.value;
            }
            case FormStepTypes.MAPPER: {
                const elements: MapperElement<any>[] = value?.elements?.filter(
                    (element: any) => element.deleted !== true
                );

                const mappedValues: Record<string, any>[] = [];
                for (const element of elements) {
                    const newValue: Record<string, any> = {
                        id: element.id,
                    };
                    for (const key of Object.keys(element.originalValues)) {
                        newValue[key] = element.originalValues[key];
                    }
                    if (element.value) newValue.value = element.value;
                    const nestedDeleteIds: string[] = [];
                    for (const idStep of Object.keys(step.steps)) {
                        const mappedId = element.ids[idStep];
                        if (values[mappedId] !== undefined) {
                            newValue[idStep] = calcValue(
                                idStep,
                                step.steps,
                                values,
                                customSteps,
                                nestedDeleteIds,
                                values[mappedId]
                            );
                        }
                    }
                    for (const idStep of nestedDeleteIds) {
                        delete newValue[idStep];
                    }
                    deleteIds.push(...nestedDeleteIds);
                    deleteIds.push(...Object.values(element.ids));
                    mappedValues.push(newValue);
                }
                return mappedValues;
            }
            case FormStepTypes.TITLE: {
                if (!value) deleteIds.push(idStep);
                return value ?? undefined;
            }
            case FormStepTypes.COLLAPSIBLE:
                deleteIds.push(idStep);
                return;
            default:
                if ((step?.type as any) === 'CBR_INCIDENCIAS') {
                    return value?.filter(
                        (incidencia: any) => incidencia.deleted !== true
                    );
                }
                return value;
        }
    }
};

function stringToDraft(text: string): any {
    const draftStructure = {
        blocks: [] as any[],
        entityMap: {},
    };
    text.split('\n').forEach((element, index) => {
        draftStructure.blocks.push({
            key: String(index),
            text: element,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        });
    });
    return draftStructure as RawDraftContentState;
}

export function calcDependencies(
    steps: Record<string, FormStep | CBRFormStep>,
    customSteps: Record<string, CustomStep> = {},
    allSteps: Record<string, FormStep | CBRFormStep> = steps,
    dependencies: DependencyStore = {},
    values: ValuesStore = {
        global: {},
        sections: {},
    },
    addDependents = true
): DependencyStore {
    for (const step of Object.values(steps)) {
        const idDeps = calcStepDeps(step);

        for (const idDep of idDeps) {
            if (
                dependencies[idDep] === undefined &&
                (addDependents || allSteps[idDep])
            )
                dependencies[idDep] = calcStepDependency(
                    idDep,
                    allSteps,
                    values,
                    customSteps
                );
            if (
                addDependents &&
                !dependencies[idDep].dependents.find(
                    (dep) => dep.id === step.id
                )
            )
                dependencies[idDep].dependents.push(step);
        }
        if (step.type === FormStepTypes.ENTITYVALUEPICKER) {
            for (const dep of [...step.path, ...step.filters]) {
                if (dep.type !== EntityValueDataTypes.STEP) continue;
                if (step.dependencies?.includes(dep.idStep)) continue;
                if (
                    dependencies[dep.idStep] === undefined &&
                    (addDependents || allSteps[dep.idStep])
                ) {
                    dependencies[dep.idStep] = calcStepDependency(
                        dep.idStep,
                        allSteps,
                        values,
                        customSteps
                    );
                }
                if (addDependents)
                    dependencies[dep.idStep].dependents.push(step);
            }
        }
        if (step.type === FormStepTypes.MAPPER) {
            const subSteps = calcMapperSubSteps(
                step,
                values.sections[step.idSection]?.[step.id]?.elements,
                customSteps
            );
            const hasSubSteps = Object.keys(subSteps).length > 0;
            calcDependencies(
                hasSubSteps ? subSteps : step.steps,
                customSteps,
                { ...allSteps, ...subSteps },
                dependencies,
                values,
                hasSubSteps
            );
        }
    }
    return dependencies;
}

export const calcMapperSubSteps = (
    step: Mapper,
    elements: MapperElement<any>[] = [],
    customSteps: Record<string, CustomStep>
): Record<string, FormStep> => {
    const newSteps: Record<string, FormStep> = {};
    for (const element of elements) {
        const elemSteps: Record<string, FormStep> = {};
        for (const idSubStep of Object.keys(step.steps)) {
            const baseStep = JSON.parse(
                JSON.stringify(step.steps[idSubStep] ?? '{}')
            );
            const newIdStep = element.ids[idSubStep];
            baseStep.id = newIdStep;
            elemSteps[newIdStep] = baseStep;
            newSteps[newIdStep] = baseStep;
        }
        calcRecursiveData(element, elemSteps, customSteps);
    }
    return newSteps;
};

function calcStepDependency(
    idStep: string,
    steps: Record<string, FormStep | CBRFormStep>,
    values: ValuesStore,
    customSteps: Record<string, CustomStep>
): StepDependency {
    const depStep = steps[idStep];
    if (!depStep) {
        const originalValue = values.global[idStep];
        if (!originalValue) console.error('Missing Step Dependency:', idStep);
        return {
            type: originalValue ? 'ORIGINAL' : ('MISSING' as any),
            value: originalValue ?? null,
            dependents: [],
        };
    }
    const originalValue =
        values.sections[depStep.idSection]?.[depStep.id] ??
        values.global[depStep.id];
    return {
        type: depStep.type,
        value: calcStepDependencyValue(depStep, originalValue, customSteps),
        dependents: [],
    };
}

function calcStepDependencyValue(
    depStep: FormStep | CBRFormStep,
    originalValue: any,
    customSteps: Record<string, CustomStep>
): StepDependency['value'] {
    const custom = customSteps[depStep?.type];
    if (custom?.calcDependencyValue) {
        return custom.calcDependencyValue(depStep, originalValue);
    }
    switch (depStep.type) {
        case FormStepTypes.TEXTAREA: {
            if (depStep.hasTextEditor) {
                return originalValue
                    ? convertFromRaw(originalValue).getPlainText()
                    : null;
            } else {
                return originalValue ?? null;
            }
        }
        case FormStepTypes.MAPPER: {
            //TODO: Esto aun no se usa
            return {
                elements: originalValue?.map(
                    (mapperValue: any) => mapperValue.value ?? mapperValue
                ),
                page: 0,
            };
        }
        default:
            return originalValue ?? null;
    }
}
